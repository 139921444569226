import React from 'react'
import NavBar from '../components/NavBar'

const underconstruction = () => {
  return (
    <div>
        <NavBar />
        <div className='flex flex-col'>
            {/* <div className='flex flex-row text-3xl font-bold justify-center mt-4'>Under Construction</div>
            <div className='flex flex-row justify-center'>We're working hard to get our website complete. Stay tuned for updates on ToothBuddy.org! </div> */}
            <p className='text-3xl font-bold text-center mt-4'>Under Construction</p>
            <p className='text-center'>We're working hard to get our website complete. Stay tuned for updates on ToothBuddy.org!</p>

        </div>
    </div>
  )
}

export default underconstruction